import { Box, Typography, Chip, CardContent } from "@mui/material";
import { styled } from "@mui/material/styles";

//

export const StyledCard = styled(Box)(({ theme }) => ({
  position: "relative",
  height: "auto",
  color: theme.palette.primary.dark,
  transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  backgroundColor:
    theme.palette.mode === "dark"
      ? theme.palette.mainBg.secondary
      : theme.palette.grey[50],
  backgroundClip: "border-box",
  border: `1px solid ${theme.palette.primary.darker}`,
  borderRadius: "1rem",
  width: "100%",
  display: "flex",
  flexDirection: "column",
  overflow: "hidden",
  "&:hover": {
    boxShadow: "rgb(20 20 20 / 25%) 0rem 1.4375rem 2.8125rem -0.6875rem",
  },
  [theme.breakpoints.up("md")]: {
    flexDirection: "row-reverse",
    maxHeight: 450,
    height: "100%",
  },
}));

export const ImageWrapper = styled(Box)(({ theme }) => ({
  display: "block",
  width: "100%",

  [theme.breakpoints.up("md")]: {
    width: "50%",
  },
}));

export const CardMediaStyle = styled(Box)(({ theme }) => ({
  width: "100%",
  background: "transparent",
  height: "100%",
  overflow: "hidden",
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

export const CoverImgStyle = styled(Box)(({ theme }) => ({
  borderRadius: "1rem",
  width: "100%",
  height: "100%",
  maxWidth: "100%",
  margin: 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  [theme.breakpoints.up("md")]: {
    borderRadius: " 0 1rem 1rem 0",
  },
}));

export const TitleStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.boldH3,
  marginBottom: theme.spacing(1),
  color:
    theme.palette.mode === "dark"
      ? theme.palette.primary.light
      : theme.palette.primary.dark,
}));

export const TechListItemWrapper = styled(Typography)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  gap: theme.spacing(2),
  margin: theme.spacing(2, 0),
}));

export const CardContentInfo = styled(CardContent)(({ theme }) => ({
  display: "flex",
  overflow: "auto",
  flexDirection: "column",
  justifyContent: "center",
  gap: theme.spacing(2),
  padding: theme.spacing(5),
}));

export const TechListItem = styled(Chip)(({ theme }) => ({
  ...theme.typography.boldSubhead,
  fontWeight: 700,
  backgroundColor: theme.palette.secondary.lighter,
  border: `1px solid ${theme.palette.secondary.main}`,
  padding: theme.spacing(2),
  color:
    theme.palette.mode === "dark"
      ? theme.palette.primary.light
      : theme.palette.primary.dark,
}));

export const Description = styled(Typography)(({ theme }) => ({
  ...theme.typography.lightSubTitle,
  color:
    theme.palette.mode === "dark"
      ? theme.palette.primary.light
      : theme.palette.primary.dark,
  overflow: "hidden",
}));

export const DrillDownText = styled(Typography)(({ theme }) => ({
  ...theme.typography.lightSubTitle,
  textDecoration: "none",
  color:
    theme.palette.mode === "dark"
      ? theme.palette.primary.light
      : theme.palette.primary.dark,

  ":hover": {
    textDecoration: "underline",
  },
}));
