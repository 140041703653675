import { CssBaseline } from "@mui/material";
import {
  createTheme,
  StyledEngineProvider,
  ThemeProvider,
} from "@mui/material/styles";
import PropTypes from "prop-types";
import { useContext, useMemo } from "react";
import palette from "./palette";
import typography from "./typography";
import ComponentsOverrides from "./overrides";
import { ThemeContext, ThemeContextProvider } from "../store/ThemeContext";

// ----------------------------------------------------------------------

ThemeConfig.propTypes = {
  children: PropTypes.node,
};

export default function ThemeConfig({ children }) {
  const context = useContext(ThemeContext);

  const theme = createTheme({
    palette: {
      ...palette,
      mode: context.theme,
    },
    typography,
  });
  theme.components = ComponentsOverrides(theme);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  );
}
