import { Box, Container, Divider, Grid, Stack, useTheme } from "@mui/material";
import React from "react";
import {
  CoverImgStyle,
  ProjectDescription,
  ProjectTitle,
  TagTitle,
  TechTagListItem,
  TechTagListItemWrapper,
} from "./Style";

import ProjectOneImg1 from "../../../assets/portfolio/customersupportbot/screenshot1.png";
import ProjectOneImg2 from "../../../assets/portfolio/customersupportbot/screenshot2.png";

import Slider from "react-slick";
//

// ----------------------------------------------------------------------
function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  const theme = useTheme();
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      style={{
        ...style,
        height: "2rem",
        width: "2rem",
        display: "block",
        color: theme.palette.primary.main,
        fontSize: "3em",
        right: "9px",
        zIndex: 1,
      }}
      onClick={onClick}
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M9 5l7 7-7 7"
      />
    </svg>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  const theme = useTheme();

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      style={{
        ...style,
        height: "2rem",
        width: "2rem",
        display: "block",
        color: theme.palette.primary.main,

        fontSize: "3em",
        left: "9px",
        zIndex: 1,
      }}
      onClick={onClick}
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M15 19l-7-7 7-7"
      />
    </svg>
  );
}

const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  nextArrow: <SampleNextArrow />,
  prevArrow: <SamplePrevArrow />,
  autoplay: true,
};

//
const CustomerSupportBot = () => {
  const tags = ["LLM", "AWS", "Vector Database"];

  const projectImages = [`${ProjectOneImg1}`, `${ProjectOneImg2}`];

  const theme = useTheme();
  return (
    <Container>
      <Grid container spacing={3} my={5}>
        <Grid item xs={12}>
          <Stack direction="column" spacing={3}>
            <ProjectTitle>Customer Support Q&A System</ProjectTitle>

            <Divider color={theme.palette.primary.light} />

            <Stack direction="column" spacing={1.5}>
              <TagTitle> TAGS </TagTitle>
              <TechTagListItemWrapper>
                {tags.map((tech) => {
                  return (
                    <Stack direction="row">
                      <TechTagListItem label={tech} variant="outlined" />
                    </Stack>
                  );
                })}
              </TechTagListItemWrapper>
            </Stack>

            <Stack direction="column" spacing={1.5}>
              <TagTitle> DESCRIPTION </TagTitle>
              <ProjectDescription>
                In the "Customer Support Q&A System" project, I collaborated
                with a SaaS provider for staffing agencies to create a system
                using Large Language Models (LLM) and AWS. This innovative
                system efficiently handles customer inquiries, guides them to
                relevant resources, and continuously improves through user
                feedback. It's a significant enhancement to the client support
                capabilities of staffing agencies, leveraging advanced AI to
                deliver accurate and adaptive customer service.
              </ProjectDescription>
            </Stack>

            <TagTitle>INTELLECTUAL PROPERTY OWNER: Enginehire Inc.</TagTitle>

            <Box style={{ margin: "42px 0 0 0" }}>
              <iframe
                width="100%"
                height="420"
                src="https://www.youtube.com/embed/63t3l5G-t3o?si=0JZtIlj1xIV-rrU_"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
            </Box>

            <TagTitle>ROLE : Consultant</TagTitle>

            {/* Etc description */}
            <Stack direction="column" spacing={1.5}>
              <ProjectDescription>
                In this consulting project, I worked with a company offering
                Software as a Service (SaaS) solutions to staffing agencies,
                focusing on developing a cutting-edge Customer Support Q&A
                System. This system, powered by Large Language Models (LLM) and
                AWS, was designed to address a wide range of customer queries
                efficiently and accurately. A key feature of the system is its
                ability to not only provide immediate answers to customer
                questions but also guide users to relevant resources and
                references for further information. An integral part of the
                project was the implementation of a feedback loop, where the
                system captures user responses and continuously learns from
                them. This iterative improvement process ensures the system
                evolves and adapts to changing customer needs and queries,
                further enhancing its effectiveness and accuracy. The system is
                sully automated and updates on a weekly cadence. The project
                successfully delivered a robust, intelligent customer support
                tool that significantly improves the service quality of staffing
                agencies, making it a valuable asset in their digital toolkit.
              </ProjectDescription>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </Container>
  );
};

export default CustomerSupportBot;
