import React from "react";

const getInitialTheme = () => localStorage.getItem("color-theme");

export const ThemeContext = React.createContext();

export const ThemeContextProvider = ({ initialTheme, children }) => {
  const [theme, setTheme] = React.useState(initialTheme);

  React.useEffect(() => {
    const _defaultTheme = getInitialTheme() || initialTheme;

    setTheme(_defaultTheme);
  }, [theme, initialTheme]);

  const handleSwitchTheme = () => {
    const _theme = theme === "light" ? "dark" : "light";
    setTheme(_theme);
    localStorage.setItem("color-theme", _theme);
  };

  return (
    <ThemeContext.Provider
      value={{
        theme,
        changeTheme: handleSwitchTheme,
        isThemeDark: theme === "dark",
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
};
