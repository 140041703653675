import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

//

export const StyledCard = styled(Box)(({ theme }) => ({
  position: "relative",
  height: 550,
  boxShadow: "none",
  // color: theme.palette.grey.dark,
  transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  minWidth: "0px",
  overflowWrap: "break-word",
  background:
    theme.palette.mode === "dark"
      ? theme.palette.mainBg.secondary
      : theme.palette.grey[50],
  borderColor: theme.palette.grey[100],
  backgroundClip: "border-box",
  border: "1px solid rgba(0, 0, 0, 0.125)",
  borderRadius: "1rem",
  display: "flex",
  flexDirection: "column",
  overflow: "visible",
  "&:hover": {
    boxShadow: "rgb(20 20 20 / 25%) 0rem 1.4375rem 2.8125rem -0.6875rem",
  },
}));

export const CardMediaStyle = styled(Box)(({ theme }) => ({
  position: "relative",
  width: "100%",
  opacity: 1,
  background: "transparent",
  borderRadius: "1rem",
}));

export const CoverImgStyle = styled(Box)(({ theme }) => ({
  display: "block",
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center center",
  borderRadius: "1rem 1rem 0 0",
  width: "100%",
  height: 400,
  maxWidth: "100%",
  margin: 0,
  boxShadow:
    "rgb(20 20 20 / 12%) 0rem 0.25rem 0.375rem -0.0625rem, rgb(20 20 20 / 7%) 0rem 0.125rem 0.25rem -0.0625rem",
  objectFit: "fill",
  objectPosition: "center center",
}));

export const TitleStyle = styled("a")(({ theme }) => ({
  textDecoration: "none",
  overFlow: "hidden",
  WebkitLineClamp: "auto",
  display: "-webkit-box",
  WebkitBoxOrient: "vertical",
  marginBottom: theme.spacing(1),
  ...theme.typography.boldH4,
  color:
    theme.palette.mode === "dark"
      ? theme.palette.primary.light
      : theme.palette.primary.dark,
  "&:hover": {
    textDecoration: "underline",
  },
}));

export const CreatedDate = styled(Typography)(({ theme }) => ({
  ...theme.typography.lightSubhead,
  display: "block",
  marginBottom: theme.spacing(2),
  color:
    theme.palette.mode === "dark"
      ? theme.palette.grey.lighter
      : theme.palette.primary.dark,
}));

export const TimeToRead = styled(Typography)(({ theme }) => ({
  ...theme.typography.lightSubhead,
  display: "block",
  marginBottom: theme.spacing(2),
  color:
    theme.palette.mode === "dark"
      ? theme.palette.grey.lighter
      : theme.palette.primary.dark,
}));
