// ----------------------------------------------------------------------

const GREY = {
  lighter: "#F4F4F6",
  lighter2: "#E3E3E8",
  light: "#CCCCD6",
  light2: "#B9B9C6",
  main: "#A5A6B6",
  main2: "#898A9F",
  dark: "#6E6F87",
  dark2: "#57586B",
  darker: "#40414F",
};
const DARK = {
  lighter: "#E2E4E7",
  lighter2: "#ADB2BB",
  light: "#767E8E",
  light2: "#494F5A",
  main: "#1B1D21",
  main2: "#1C1E22",
  dark: "#191B1F",
  dark2: "#191B1F",
  darker: "#17191C",
};
const PRIMARY = {
  lighter: "#53B3C0",
  light: "#3EA9B8",
  main: "#29A0B1",
  dark: "#24909F",
  darker: "#20808D",
};
const SECONDARY = {
  lighter: "#CBDEFE",
  light: "#A2DBC8",
  main: "#98D7C2",
  dark: "#5B8174",
  darker: "#4C6B61",
};

const MAINBG = {
  light: "#fff",
  dark: "#010F17", // 030614
  secondary: "#02131D", //070E13
};

const palette = {
  common: { black: "#000", white: "#fff" },
  primary: { ...PRIMARY },
  secondary: { ...SECONDARY },
  dark: { ...DARK },
  grey: GREY,
  mainBg: { ...MAINBG },
};

export default palette;
