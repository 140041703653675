import { Box, Container, Divider, Grid, Stack, useTheme } from "@mui/material";
import React from "react";
import {
  CoverImgStyle,
  ProjectDescription,
  ProjectTitle,
  TagTitle,
  TechTagListItem,
  TechTagListItemWrapper,
} from "./Style";

import PorjectOneImg1 from "../../../assets/portfolio/cloudinfastructure/aws_alert.drawio.png";

import Slider from "react-slick";
//

// ----------------------------------------------------------------------
function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  const theme = useTheme();
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      style={{
        ...style,
        height: "2rem",
        width: "2rem",
        display: "block",
        color: theme.palette.primary.main,
        fontSize: "3em",
        right: "9px",
        zIndex: 1,
      }}
      onClick={onClick}
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M9 5l7 7-7 7"
      />
    </svg>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  const theme = useTheme();

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      style={{
        ...style,
        height: "2rem",
        width: "2rem",
        display: "block",
        color: theme.palette.primary.main,

        fontSize: "3em",
        left: "9px",
        zIndex: 1,
      }}
      onClick={onClick}
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M15 19l-7-7 7-7"
      />
    </svg>
  );
}

const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  nextArrow: <SampleNextArrow />,
  prevArrow: <SamplePrevArrow />,
  autoplay: true,
};

//
const CloudInfastructure = () => {
  const tags = ["AWS SES", "AWS EventBridge", "AWS Lambda"];

  const projectImages = [`${PorjectOneImg1}`];

  const theme = useTheme();
  return (
    <Container>
      <Grid container spacing={3} my={5}>
        <Grid item xs={12}>
          <Stack direction="column" spacing={3}>
            <ProjectTitle>Cloud Infastructure</ProjectTitle>

            <Divider color={theme.palette.primary.light} />

            <Stack direction="column" spacing={1.5}>
              <TagTitle> TAGS </TagTitle>
              <TechTagListItemWrapper>
                {tags.map((tech) => {
                  return (
                    <Stack direction="row">
                      <TechTagListItem label={tech} variant="outlined" />
                    </Stack>
                  );
                })}
              </TechTagListItemWrapper>
            </Stack>

            <Stack direction="column" spacing={1.5}>
              <TagTitle> DESCRIPTION </TagTitle>
              <ProjectDescription>
                Worked with the Enginehire team to reudce cloud cost and set up
                alerts
              </ProjectDescription>
            </Stack>

            <TagTitle>INTELLECTUAL PROPERTY OWNER: Enginehire Inc.</TagTitle>

            <Box style={{ margin: "42px 0 0 0" }}>
              {projectImages && (
                <Slider {...settings}>
                  {projectImages.map((image) => {
                    return (
                      <CoverImgStyle component="img" alt="img" src={image} />
                    );
                  })}
                </Slider>
              )}
            </Box>

            <TagTitle>ROLE : Consultant</TagTitle>

            {/* Etc description */}
            <Stack direction="column" spacing={1.5}>
              <ProjectDescription>
                I collaborated with the Enginehire team on a strategic project
                aimed at optimizing their cloud infrastructure for cost
                efficiency. Our primary goal was to significantly reduce
                cloud-related expenses without compromising system performance
                or reliability. To achieve this, we conducted a thorough
                analysis of their current cloud usage, identifying areas where
                costs could be trimmed. This involved reviewing and optimizing
                resource allocation, scaling policies, and choosing more
                cost-effective service options where possible. In addition to
                cost reduction, we also focused on enhancing the system's
                responsiveness to potential issues. We set up a comprehensive
                alert system using advanced monitoring tools. This system was
                designed to promptly notify the team of any irregularities or
                performance issues in the cloud environment, allowing for swift
                and proactive management. The alert mechanism was tailored to
                track specific metrics and thresholds relevant to Enginehire's
                operational needs, ensuring that the team stayed informed and in
                control at all times. Overall, this collaboration with the
                Enginehire team not only led to significant savings in cloud
                costs but also established a robust framework for ongoing system
                monitoring and alert management, contributing to the overall
                efficiency and stability of their cloud operations
              </ProjectDescription>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </Container>
  );
};

export default CloudInfastructure;
