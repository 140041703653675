import PropTypes from "prop-types";
import { Grid, CardContent } from "@mui/material";
// ----------------------------------------------------------------------

import {
  CardMediaStyle,
  StyledCard,
  CoverImgStyle,
  TitleStyle,
} from "./WritingCardStyle";

// ----------------------------------------------------------------------

WritingCard.propTypes = {
  post: PropTypes.object.isRequired,
  index: PropTypes.number,
};

export default function WritingCard({ post }) {
  const { title, image, link } = post;

  return (
    <Grid item xs={12}>
      <StyledCard>
        <CardMediaStyle>
          <CoverImgStyle component="img" alt={title} src={image} />
        </CardMediaStyle>

        <CardContent
          sx={{
            pt: 4,
          }}
        >
          <TitleStyle href={link} target="_blank">
            {title}
          </TitleStyle>
        </CardContent>
      </StyledCard>
    </Grid>
  );
}
