import { Container, Grid, Pagination, Stack } from "@mui/material";
import React from "react";
import Banner from "../../components/common/Banner";
import PORTFOLIO from "../../_mocks_/portfolio";
import PortfolioCard from "./PortfolioCard";
//

const Portfolio = () => {
  const itemsPerPage = 2;
  const [page, setPage] = React.useState(1);
  const [noOfPages] = React.useState(
    Math.ceil(PORTFOLIO.length / itemsPerPage)
  );

  const handleChange = (event, value) => {
    setPage(value);
  };

  return (
    <>
      <Banner
        title="Portfolio"
        message="Consulting projects I have worked on recently"
      />
      <Container>
        <Grid container spacing={3} my={10}>
          {PORTFOLIO.slice((page - 1) * itemsPerPage, page * itemsPerPage).map(
            (portfolio, index) => (
              <PortfolioCard
                key={portfolio.id}
                portfolio={portfolio}
                index={index}
              />
            )
          )}
        </Grid>

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          mt={8}
          spacing={2}
        >
          <Pagination
            count={noOfPages}
            page={page}
            onChange={handleChange}
            defaultPage={1}
            color="primary"
            size="large"
            showFirstButton
            showLastButton
          />
        </Stack>
      </Container>{" "}
    </>
  );
};

export default Portfolio;
