import { useRoutes } from "react-router-dom";
import { Layout } from "./components/Layout";
import About from "./pages/about/About";
import Portfolio from "./pages/portfolio/Portfolio";
import DataDashboard from "./pages/portfolio/projects/DataDashboard";
import CustomerSupportBot from "./pages/portfolio/projects/CustomerSupportBot.js";
import CloudInfastructure from "./pages/portfolio/projects/CloudInfastructure";
import AllergenDetector from "./pages/portfolio/projects/AllergenDetector";
import Writing from "./pages/writing/Writing";
import Speaker from "./pages/speaker/Speaker";

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: "/",
      element: <Layout />,
      children: [
        { path: "/", element: <About /> },
        { path: "/about", element: <About /> },
        { path: "/portfolio", element: <Portfolio /> },
        { path: "/writing", element: <Writing /> },
        { path: "/speaker", element: <Speaker /> },
        { path: "/data-dashboard", element: <DataDashboard /> },
        { path: "/cloud-infastructure", element: <CloudInfastructure /> },
        { path: "/customersupport-bot", element: <CustomerSupportBot /> },
        { path: "/allergen-detector", element: <AllergenDetector /> },
      ],
    },
    { path: "*", element: <Layout to="/" replace /> },
  ]);
}
