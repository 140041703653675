import { Box, Typography, Chip } from "@mui/material";
import { styled } from "@mui/material/styles";

//

export const ProjectTitle = styled(Typography)(({ theme }) => ({
  ...theme.typography.boldH3,
  color:
    theme.palette.mode === "dark"
      ? theme.palette.primary.light
      : theme.palette.primary.dark,
}));

export const TechTagListItemWrapper = styled(Typography)(({ theme }) => ({
  display: "flex",
  flexWrap: "wrap",
  gap: theme.spacing(2),
  margin: theme.spacing(2, 0),
  flexDirection: "row",
  alignItems: "center",
}));

export const TagTitle = styled(Typography)(({ theme }) => ({
  ...theme.typography.boldSubhead,
  color:
    theme.palette.mode === "dark"
      ? theme.palette.primary.lighter
      : theme.palette.primary.darker,
}));

export const ProjectDescription = styled(Typography)(({ theme }) => ({
  ...theme.typography.lightSubTitle,
  color:
    theme.palette.mode === "dark"
      ? theme.palette.primary.light
      : theme.palette.grey.darker,
}));

export const TechTagListItem = styled(Chip)(({ theme }) => ({
  ...theme.typography.boldSubhead,
  fontWeight: 700,
  backgroundColor: theme.palette.secondary.lighter,
  border: `1px solid ${theme.palette.secondary.main}`,
  padding: theme.spacing(2, 3),
  color:
    theme.palette.mode === "dark"
      ? theme.palette.primary.light
      : theme.palette.primary.dark,
}));

export const CardMediaStyle = styled(Box)(({ theme }) => ({
  width: "100%",
  background: "transparent",
}));

export const CoverImgStyle = styled(Box)(({ theme }) => ({
  borderRadius: "1rem",
  width: "100%",
  height: 150,
  maxWidth: "100%",
  margin: 0,
  objectFit: "fill",
  objectPosition: "center center",
  border: `1px solid ${theme.palette.primary.light}`,
  [theme.breakpoints.up("md")]: {
    height: 450,
  },
}));

export const Abc = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {},
}));
