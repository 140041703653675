import { Container, Grid, Pagination, Stack } from "@mui/material";
import React from "react";
import Banner from "../../components/common/Banner";
import SPEAKER from "../../_mocks_/speaker";
import SpeakerCard from "./SpeakerCard";
//

const Speaker = () => {
  const itemsPerPage = 4;
  const [page, setPage] = React.useState(1);
  const [noOfPages] = React.useState(Math.ceil(SPEAKER.length / itemsPerPage));

  const handleChange = (event, value) => {
    setPage(value);
  };

  return (
    <>
      <Banner title="Speaker" message="Convey your thoughts" />
      <Container>
        <Grid container spacing={3} my={10}>
          {SPEAKER.slice((page - 1) * itemsPerPage, page * itemsPerPage).map(
            (speaker, index) => (
              <SpeakerCard key={speaker.id} speaker={speaker} index={index} />
            )
          )}
        </Grid>

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          mt={8}
          spacing={2}
        >
          <Pagination
            count={noOfPages}
            page={page}
            onChange={handleChange}
            defaultPage={1}
            color="primary"
            size="large"
            showFirstButton
            showLastButton
          />
        </Stack>
      </Container>{" "}
    </>
  );
};

export default Speaker;
