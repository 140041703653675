import React from "react";
import PythonIcon from "../../assets/toolkit/Python.png";
import HuggingFaceIcon from "../../assets/toolkit/HuggingFace.png";
import PandasIcon from "../../assets/toolkit/Pandas.png";
import PostgreSQLIcon from "../../assets/toolkit/PostgreSQL.png";
import MySQLIcon from "../../assets/toolkit/MySQL.png";
import DockerIcon from "../../assets/toolkit/Docker.png";
import KubernetesIcon from "../../assets/toolkit/Kubernetes.png";
import LocustIcon from "../../assets/toolkit/Locust.png";
import PytorchIcon from "../../assets/toolkit/Pytorch.png";
import GitIcon from "../../assets/toolkit/Git.png";
import LinuxIcon from "../../assets/toolkit/Linux.png";
import SpaCyIcon from "../../assets/toolkit/SpaCy.png";
// Hypothesis testing
import ScaleIcon from "../../assets/toolkit/Scale.png";
import GrafanaIcon from "../../assets/toolkit/Grafana.png";
import MatplotlibIcon from "../../assets/toolkit/Matplotlib.png";
import SciPyIcon from "../../assets/toolkit/SciPy.png";
import VScodeIcon from "../../assets/toolkit/VScode.png";
import SeabornIcon from "../../assets/toolkit/Seaborn.png";
import ServerlessIcon from "../../assets/toolkit/Serverless.png";
import SnowflakeIcon from "../../assets/toolkit/Snowflake.png";
import FastAPIIcon from "../../assets/toolkit/FastAPI.png";
import NumpyIcon from "../../assets/toolkit/Numpy.png";
import ScikitLearnIcon from "../../assets/toolkit/ScikitLearn.png";
import AWSIcon from "../../assets/toolkit/AWS.png";
import OpenAIIcon from "../../assets/toolkit/openai.png";
import AzureMLStudioIcon from "../../assets/toolkit/azuremlstudio.png";

import { IconBox, IconBoxWrapper, ToolkitBoxWrapper } from "./AboutStyle";
import { Tooltip, Zoom } from "@mui/material";

const Toolkit = () => {
  return (
    <>
      <ToolkitBoxWrapper item xs={12}>
        {/* Python */}
        <Tooltip TransitionComponent={Zoom} title="Python">
          <IconBoxWrapper>
            <IconBox component="img" src={PythonIcon} alt="Python" />
          </IconBoxWrapper>
        </Tooltip>

        {/* AWS */}
        <Tooltip TransitionComponent={Zoom} title="AWS">
          <IconBoxWrapper>
            <IconBox component="img" src={AWSIcon} alt="AWS" />
          </IconBoxWrapper>
        </Tooltip>

        {/* Hugging face */}
        <Tooltip TransitionComponent={Zoom} title="Hugging face">
          <IconBoxWrapper>
            <IconBox component="img" src={HuggingFaceIcon} alt="Hugging face" />
          </IconBoxWrapper>
        </Tooltip>

        {/* OpenAI */}
        <Tooltip TransitionComponent={Zoom} title="OpenAI">
          <IconBoxWrapper>
            <IconBox component="img" src={OpenAIIcon} alt="OpenAI" />
          </IconBoxWrapper>
        </Tooltip>

        {/* Azure Studio */}
        <Tooltip TransitionComponent={Zoom} title="Azure ML Studio">
          <IconBoxWrapper>
            <IconBox
              component="img"
              src={AzureMLStudioIcon}
              alt="Azure ML Studio"
            />
          </IconBoxWrapper>
        </Tooltip>

        {/* pandas */}
        <Tooltip TransitionComponent={Zoom} title="Pandas">
          <IconBoxWrapper>
            <IconBox component="img" src={PandasIcon} alt="Pandas" />
          </IconBoxWrapper>
        </Tooltip>

        {/* PostgreSQL */}
        <Tooltip TransitionComponent={Zoom} title="PostgreSQL">
          <IconBoxWrapper>
            <IconBox component="img" src={PostgreSQLIcon} alt="PostgreSQL" />
          </IconBoxWrapper>
        </Tooltip>

        {/* MySQL  */}
        <Tooltip TransitionComponent={Zoom} title="MySQL">
          <IconBoxWrapper>
            <IconBox component="img" src={MySQLIcon} alt="MySQL" />
          </IconBoxWrapper>
        </Tooltip>

        {/* FastAPI 
        <Tooltip TransitionComponent={Zoom} title="FastAPI">
          <IconBoxWrapper>
            <IconBox component="img" src={FastAPIIcon} alt="FastAPI" />
          </IconBoxWrapper>
        </Tooltip>*/}

        {/* Docker */}
        <Tooltip TransitionComponent={Zoom} title="Docker">
          <IconBoxWrapper>
            <IconBox component="img" src={DockerIcon} alt="Docker" />
          </IconBoxWrapper>
        </Tooltip>

        {/* Seaborn */}
        <Tooltip TransitionComponent={Zoom} title="Seaborn">
          <IconBoxWrapper>
            <IconBox component="img" src={SeabornIcon} alt="Seaborn" />
          </IconBoxWrapper>
        </Tooltip>

        {/* Serverless */}
        <Tooltip TransitionComponent={Zoom} title="Serverless">
          <IconBoxWrapper>
            <IconBox component="img" src={ServerlessIcon} alt="Serverless" />
          </IconBoxWrapper>
        </Tooltip>

        {/* Snowflake */}
        <Tooltip TransitionComponent={Zoom} title="Snowflake">
          <IconBoxWrapper>
            <IconBox component="img" src={SnowflakeIcon} alt="Snowflake" />
          </IconBoxWrapper>
        </Tooltip>

        {/* Scikit learn */}
        <Tooltip TransitionComponent={Zoom} title="Scikit Learn">
          <IconBoxWrapper>
            <IconBox component="img" src={ScikitLearnIcon} alt="Scikit learn" />
          </IconBoxWrapper>
        </Tooltip>

        {/* NumPy */}
        <Tooltip TransitionComponent={Zoom} title="NumPy">
          <IconBoxWrapper>
            <IconBox component="img" src={NumpyIcon} alt="NumPy" />
          </IconBoxWrapper>
        </Tooltip>

        {/* vscode */}
        <Tooltip TransitionComponent={Zoom} title="VScode">
          <IconBoxWrapper>
            <IconBox component="img" src={VScodeIcon} alt="VScode" />
          </IconBoxWrapper>
        </Tooltip>

        {/* Kubernetes */}
        <Tooltip TransitionComponent={Zoom} title="Kubernetes">
          <IconBoxWrapper>
            <IconBox
              component="img"
              src={KubernetesIcon}
              alt="pythoKubernetes"
            />
          </IconBoxWrapper>
        </Tooltip>

        {/* Locust */}
        <Tooltip TransitionComponent={Zoom} title="Locust">
          <IconBoxWrapper>
            <IconBox component="img" src={LocustIcon} alt="pLocust" />
          </IconBoxWrapper>
        </Tooltip>

        {/* Pytorch */}
        <Tooltip TransitionComponent={Zoom} title="Pytorch">
          <IconBoxWrapper>
            <IconBox component="img" src={PytorchIcon} alt="pyPytorch" />
          </IconBoxWrapper>
        </Tooltip>

        {/* Git */}
        <Tooltip TransitionComponent={Zoom} title="Git">
          <IconBoxWrapper>
            <IconBox component="img" src={GitIcon} alt="Git" />
          </IconBoxWrapper>
        </Tooltip>

        {/* Linux */}
        <Tooltip TransitionComponent={Zoom} title="Linux">
          <IconBoxWrapper>
            <IconBox component="img" src={LinuxIcon} alt="Linux" />
          </IconBoxWrapper>
        </Tooltip>

        {/* SpaCy */}
        <Tooltip TransitionComponent={Zoom} title="SpaCy">
          <IconBoxWrapper>
            <IconBox component="img" src={SpaCyIcon} alt="SpaCy" />
          </IconBoxWrapper>
        </Tooltip>

        {/* Hypothesis testing */}
        <Tooltip TransitionComponent={Zoom} title="Hypothesis Testing">
          <IconBoxWrapper>
            <IconBox component="img" src={ScaleIcon} alt="Hypothesis Testing" />
          </IconBoxWrapper>
        </Tooltip>

        {/* Matplotlib */}
        <Tooltip TransitionComponent={Zoom} title="Matplotlib">
          <IconBoxWrapper>
            <IconBox
              component="img"
              src={MatplotlibIcon}
              alt="pythoMatplotlib"
            />
          </IconBoxWrapper>
        </Tooltip>

        {/* Grafana */}
        <Tooltip TransitionComponent={Zoom} title="Grafana">
          <IconBoxWrapper>
            <IconBox component="img" src={GrafanaIcon} alt="pyGrafana" />
          </IconBoxWrapper>
        </Tooltip>

        {/* SciPy */}
        <Tooltip TransitionComponent={Zoom} title="SciPy">
          <IconBoxWrapper>
            <IconBox component="img" src={SciPyIcon} alt="SciPy" />
          </IconBoxWrapper>
        </Tooltip>
      </ToolkitBoxWrapper>
    </>
  );
};

export default Toolkit;
