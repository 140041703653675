import { Box, styled, Typography } from "@mui/material";
import React from "react";

const FooterText = styled(Typography)(({ theme }) => ({
  color:
    theme.palette.mode === "dark"
      ? theme.palette.primary.light
      : theme.palette.dark.main,
}));

const FooterWrapper = styled("footer")(({ theme }) => ({
  borderTop:
    theme.palette.mode === "dark"
      ? `1px solid ${theme.palette.primary.light}`
      : `1px solid ${theme.palette.primary.light}`,
  backgroundColor:
    theme.palette.mode === "dark"
      ? theme.palette.mainBg.secondary
      : theme.palette.common.white,
  padding: theme.spacing(2.5),
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  textAlign: "center",
  width: "100%",
}));

const Footer = () => {
  return (
    <>
      <FooterWrapper>
        <FooterText variant="lightSubhead">
          {" "}
          {/* = but also © 2020 Apurva Misra. */}
        </FooterText>
        <FooterText variant="lightSubhead">
          {/* Privacy is not a small thing. Check out our Privacy Terms. */}
        </FooterText>

        <FooterText variant="lightSubhead">
          {/* Good relationships have transparent & enjoyable terms. Here are our
          Terms & Conditions. */}
        </FooterText>
      </FooterWrapper>
    </>
  );
};

export default Footer;
