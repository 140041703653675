import { Box, Typography } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import React from "react";
//

const MainStyle = styled(Box)(({ theme }) => ({
  position: "relative",
  textAlign: "center",
  background:
    theme.palette.mode === "dark"
      ? `linear-gradient(60deg, ${theme.palette.secondary.lighter} 0%, ${theme.palette.primary.lighter} 100%)`
      : `linear-gradient(60deg, ${theme.palette.secondary.main} 0%, ${theme.palette.primary.main} 100%)`,

  margin: theme.spacing(3),

  [theme.breakpoints.up("sm")]: {
    margin: theme.spacing(5, 8, 10, 8),
  },
}));

const WritingSection = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  textAlign: "center",
  height: "40vh",
  width: "100%",
  margin: 0,
  padding: 0,
}));

const WritingWrapper = styled(Box)(({ theme }) => ({
  maxWidth: "1200px",
  margin: "0 auto",
  width: "100%",
  padding: theme.spacing(1),

  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  textAlign: "center",
}));

const Title = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.darker,
}));

const Message = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.darker,
}));

//
export default function Banner(props) {
  const theme = useTheme();
  return (
    <>
      <MainStyle>
        <WritingSection>
          <WritingWrapper>
            <Title variant="boldH1">{props.title}</Title>

            <Message variant="boldH6">{props.message}</Message>
          </WritingWrapper>
        </WritingSection>

        <div>
          <svg
            className="waves"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            viewBox="0 24 150 28"
            preserveAspectRatio="none"
            shapeRendering="auto"
          >
            <defs>
              <path
                id="gentle-wave"
                d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"
              />
            </defs>
            <g className="parallax">
              <use
                xlinkHref="#gentle-wave"
                x="48"
                y="0"
                style={{
                  fill:
                    theme.palette.mode === "dark"
                      ? "rgba(3,6,20,0.7)"
                      : "rgba(255,255,255,0.7)",
                }}
              />
              <use
                xlinkHref="#gentle-wave"
                x="48"
                y="3"
                style={{
                  fill:
                    theme.palette.mode === "dark"
                      ? "rgba(3,6,20,0.5)"
                      : "rgba(255, 255, 255, 0.5)",
                }}
              />
              <use
                xlinkHref="#gentle-wave"
                x="48"
                y="5"
                style={{
                  fill:
                    theme.palette.mode === "dark"
                      ? "rgba(3,6,20,0.3)"
                      : "rgba(255, 255, 255, 0.3)",
                }}
              />
              <use
                xlinkHref="#gentle-wave"
                x="48"
                y="7"
                style={{
                  fill:
                    theme.palette.mode === "dark"
                      ? "rgba(3, 6, 20, 0.2)"
                      : theme.palette.mainBg.light,
                }}
              />
            </g>
          </svg>
        </div>
      </MainStyle>
    </>
  );
}
