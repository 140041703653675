import { Box, Container, Divider, Grid, Stack, useTheme } from "@mui/material";
import React from "react";
import {
  CoverImgStyle,
  ProjectDescription,
  ProjectTitle,
  TagTitle,
  TechTagListItem,
  TechTagListItemWrapper,
} from "./Style";

import PorjectOneImg1 from "../../../assets/portfolio/dashboard/statsagency.png";
import PorjectOneImg2 from "../../../assets/portfolio/dashboard/numbers.png";
import PorjectOneImg3 from "../../../assets/portfolio/dashboard/piechart.png";
import PorjectOneImg4 from "../../../assets/portfolio/dashboard/linegraph.png";

import Slider from "react-slick";
//

// ----------------------------------------------------------------------
function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  const theme = useTheme();
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      style={{
        ...style,
        height: "2rem",
        width: "2rem",
        display: "block",
        color: theme.palette.primary.main,
        fontSize: "3em",
        right: "9px",
        zIndex: 1,
      }}
      onClick={onClick}
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M9 5l7 7-7 7"
      />
    </svg>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  const theme = useTheme();

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      style={{
        ...style,
        height: "2rem",
        width: "2rem",
        display: "block",
        color: theme.palette.primary.main,

        fontSize: "3em",
        left: "9px",
        zIndex: 1,
      }}
      onClick={onClick}
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M15 19l-7-7 7-7"
      />
    </svg>
  );
}

const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  nextArrow: <SampleNextArrow />,
  prevArrow: <SamplePrevArrow />,
  autoplay: true,
};

//
const DataDashboard = () => {
  const tags = ["Python", "Postgres", "AWS"];

  const projectImages = [
    `${PorjectOneImg1}`,
    `${PorjectOneImg2}`,
    `${PorjectOneImg3}`,
    `${PorjectOneImg4}`,
  ];

  const theme = useTheme();
  return (
    <Container>
      <Grid container spacing={3} my={5}>
        <Grid item xs={12}>
          <Stack direction="column" spacing={3}>
            <ProjectTitle>Data Dashboard</ProjectTitle>

            <Divider color={theme.palette.primary.light} />

            <Stack direction="column" spacing={1.5}>
              <TagTitle> TAGS </TagTitle>
              <TechTagListItemWrapper>
                {tags.map((tech) => {
                  return (
                    <Stack direction="row">
                      <TechTagListItem label={tech} variant="outlined" />
                    </Stack>
                  );
                })}
              </TechTagListItemWrapper>
            </Stack>

            <Stack direction="column" spacing={1.5}>
              <TagTitle> DESCRIPTION </TagTitle>
              <ProjectDescription>
                Worked with the Enginehire team to set up relevant data
                pipelines and storage to be able to generate a dashboard for the
                customers working with them. The dashboard contains metrics that
                help customers get a bird's eye view of the operations and serve
                as the mission control.
              </ProjectDescription>
            </Stack>

            <TagTitle>INTELLECTUAL PROPERTY OWNER: Enginehire Inc.</TagTitle>

            <Box style={{ margin: "42px 0 0 0" }}>
              {projectImages && (
                <Slider {...settings}>
                  {projectImages.map((image) => {
                    return (
                      <CoverImgStyle component="img" alt="img" src={image} />
                    );
                  })}
                </Slider>
              )}
            </Box>

            <TagTitle>ROLE : Consultant</TagTitle>

            {/* Etc description */}
            <Stack direction="column" spacing={1.5}>
              <ProjectDescription>
                The dashboard included statistics to help them make decisions as
                well as graphs to visualise the numbers. They also got ability
                to choose/customize the graphs according to their liking.
              </ProjectDescription>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </Container>
  );
};

export default DataDashboard;
