// ----------------------------------------------------------------------

export default function Custom(theme) {
  return {
    MuiListItemButton: {
      styleOverrides: {
        root: {
          "&:hover": {
            backgroundColor: "transparent",
          },
        },
      },
    },

    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor:
            theme.palette.mode === "dark"
              ? theme.palette.mainBg.dark
              : theme.palette.mainBg.light,
        },
      },
    },
  };
}
