import { Box, List, ListItemText, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import {
  matchPath,
  NavLink as RouterLink,
  useLocation,
} from "react-router-dom";
//

const ListItemStyle = styled((props) => <Typography {...props} />)(
  ({ theme }) => ({
    ...theme.typography.boldSubhead,
    position: "relative",
    textDecoration: "none",
    textTransform: "capitalize",
    padding: theme.spacing(0, 2.5),
    margin: theme.spacing(0.5, 1),
    color:
      theme.palette.mode === "dark"
        ? theme.palette.primary.light
        : theme.palette.dark.main,

    transition: "all 0.5s ease",
    border: "3px solid transparent",
  })
);

const NavLinkList = styled(List)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  padding: theme.spacing(0, 5),
  margin: theme.spacing(5, 3),
  gap: theme.spacing(2),

  [theme.breakpoints.up("md")]: {
    flexDirection: "row",
    margin: theme.spacing(0),
  },
}));

NavItem.propTypes = {
  item: PropTypes.object,
  active: PropTypes.func,
};

function NavItem({ item, active }) {
  const isActiveRoot = active(item.path);
  const { title, path } = item;

  const activeRootStyle = {
    color: (theme) =>
      theme.palette.mode === "dark"
        ? theme.palette.primary.light
        : theme.palette.dark.main,

    borderBottom: (theme) =>
      theme.palette.mode === "dark"
        ? `3px solid ${theme.palette.primary.light}`
        : `3px solid ${theme.palette.common.black}`,

    "&:before": { display: "block" },
  };

  const list_item = (
    <>
      <ListItemText disableTypography primary={title} />
    </>
  );

  return (
    <ListItemStyle
      component={RouterLink}
      to={path}
      sx={{ ...(isActiveRoot && activeRootStyle) }}
    >
      {list_item}
    </ListItemStyle>
  );
}

NavLinks.propTypes = {
  navConfig: PropTypes.array,
};

export default function NavLinks({ navConfig, ...other }) {
  const { pathname } = useLocation();
  const match = (path) =>
    path ? !!matchPath({ path, end: false }, pathname) : false;

  return (
    <Box {...other}>
      <NavLinkList>
        {navConfig.map((item) => (
          <NavItem key={item.title} item={item} active={match} />
        ))}
      </NavLinkList>
    </Box>
  );
}
