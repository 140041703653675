import {
  Box,
  Stack,
  Tooltip,
  Typography,
  Zoom,
  Container,
  Divider,
} from "@mui/material";
import React from "react";
import Banner from "../../components/common/Banner";
import {
  AboutCard,
  AboutImage,
  AboutSectionLeft,
  AboutSectionNoWrap,
  AboutSectionRight,
  Book,
  Coffee,
  Podcast,
  IconButtonStyled,
  ImageWrapper,
  LinkedIn,
  Medium,
  // Name,
  TitleWrapper,
  PersonalInfo,
  SocialLinks,
  Signature,
  TitleMain,
  ToolKitSection,
  Mail,
  MailText,
} from "./AboutStyle.js";

import Toolkit from "./Toolkit";
import perimg from "../../assets/apurva_headshot.jpg";

//

//
const About = () => {
  return (
    <>
      <Banner title="About Me!!" message="learn frequently, implement often" />

      <Container>
        <AboutSectionNoWrap>
          {/* About Left Section  */}
          <AboutSectionLeft>
            <AboutCard>
              <Box>
                <ImageWrapper>
                  {/* Your Image */}
                  <AboutImage component="img" src={perimg} alt="img" />
                </ImageWrapper>

                <PersonalInfo>
                  {/* Your Name */}
                  {/* <Name variant="boldH4">Apurva Misra</Name> */}

                  {/* Signature */}
                  <Signature />

                  {/* Social media handle */}
                  <SocialLinks>
                    <Tooltip TransitionComponent={Zoom} title="LinkedIn">
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href={"https://www.linkedin.com/in/apurva-misra/"}
                      >
                        <IconButtonStyled>
                          <LinkedIn />
                        </IconButtonStyled>
                      </a>
                    </Tooltip>

                    <Tooltip TransitionComponent={Zoom} title="Good Reads">
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href={
                          "https://www.goodreads.com/user/show/74733486-apurva-misra"
                        }
                      >
                        <IconButtonStyled>
                          <Book />
                        </IconButtonStyled>
                      </a>
                    </Tooltip>

                    <Tooltip TransitionComponent={Zoom} title="Set up a call">
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href={"https://calendly.com/sentick/30min"}
                      >
                        <IconButtonStyled>
                          <Coffee />
                        </IconButtonStyled>
                      </a>
                    </Tooltip>

                    <Tooltip TransitionComponent={Zoom} title="Medium">
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href={"https://medium.com/@amisra26"}
                      >
                        <IconButtonStyled>
                          <Medium />
                        </IconButtonStyled>
                      </a>
                    </Tooltip>

                    <Tooltip TransitionComponent={Zoom} title="Podcast">
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href={"https://careerdissection.com/"}
                      >
                        <IconButtonStyled>
                          <Podcast />
                        </IconButtonStyled>
                      </a>
                    </Tooltip>
                  </SocialLinks>

                  {/*  */}

                  <Stack
                    direction="column"
                    alignItems="center"
                    my={2}
                    spacing={1}
                  >
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="mailto:hello@apurvamisra.com"
                    >
                      <IconButtonStyled>
                        <Mail />
                      </IconButtonStyled>
                    </a>

                    <MailText>hello@apurvamisra.com </MailText>
                  </Stack>
                </PersonalInfo>
              </Box>

              <Divider />

              <ToolKitSection>
                <TitleWrapper>
                  <Typography variant="boldH4">My Toolkit</Typography>
                </TitleWrapper>
                <Toolkit />
              </ToolKitSection>
            </AboutCard>
          </AboutSectionLeft>

          {/* About Right Section  */}
          <AboutSectionRight>
            <AboutCard>
              {/* 1st */}
              <Stack
                direction="column"
                justifyContent="center"
                spacing={2}
                sx={{ mb: 4 }}
              >
                {/* What can you expect from me?  */}
                <TitleMain variant="boldH4">Hey there!</TitleMain>

                {/* Description */}
                <Typography variant="lightSubTitle">
                  Howdy! Thanks for stopping by. 🙃
                  <br />
                  I’m Apurva. I am a machine learning engineer, consultant,
                  writer, entrepreneur and a lot of stuff in between. I have
                  experience in various domains including MLOps, chatbots,
                  DevOps, eye tracking, and more and have worked with teams
                  doing everything from the Uber for trucking industry, to
                  creating automated insurance agents, to finding the best
                  restaurants for people with allergies, to fighting fatigue in
                  radiologists, to trying to build Jarvis-like personal
                  assistant.
                  <br />
                  I love working with interesting people and using data and
                  technology to answer hard questions and solve challenging
                  problems. I also enjoy writing and sharing the things I’m
                  learning with others, I really believe in the equality of
                  access to education and opportunity. I’m always on the lookout
                  for exciting things to work on, if you have an idea, project
                  or company, especially related to data and ML and think I can
                  help, or just want to chat, please setup a call with meby
                  clicking on the coffee icon!
                  <br />
                  In my spare time I like to…
                  <br />
                  Watch animated movies (Abominable is my favorite!)
                  <br />
                  Try new restaurants and foods (Momos are my favorite!)
                  <br />
                  Traveling and exploring new places (I’m currently working on
                  my Spanish!)
                </Typography>
              </Stack>

              {/* 3rd */}
              <Stack
                direction="column"
                justifyContent="center"
                spacing={2}
                sx={{ mb: 4 }}
              >
                {/* Background  */}
                <TitleMain variant="boldH4">Background</TitleMain>

                {/* Description */}
                <Typography variant="lightSubTitle">
                  I am an Electronics and Communications engineer turned Machine
                  Learning Engineer with experience in various domains
                  including, eye tracking, locational, physiological, text,
                  chatbot, rate prediction, recommendation, cloud infastructure
                  and more. I did my Masters from the University of Waterloo
                  with reserach in Cognitive Driver Distraction Detection
                  (detecting driver distraction when they are thinking of
                  something else not the same as looking somewhere else), this
                  was my segway to machine learning, experimental design and
                  hypothesis testing. During and after my Masters I worked at a
                  bunch of startups using Machine learning in various ways like
                  fatigue detection for Radiologists, allergen detection in
                  restaurant menus using NLP, creating chatbots in the insurance
                  space and also worked on creating a personal assistant to book
                  you an Uber, order food etc. I am AWS Certified Solutions
                  Architect Associate and love helping startups setup an
                  efficient/robust cloud infastructure and reduce their cloud
                  cost.
                </Typography>
              </Stack>

              {/* 4th */}
              <Stack
                direction="column"
                justifyContent="center"
                spacing={2}
                sx={{ mb: 4 }}
              >
                {/* Background  */}
                <TitleMain variant="boldH4">Why Consulting?</TitleMain>

                {/* Description */}
                <Typography variant="lightSubTitle">
                  This wasn't part of my 1 year or 5 year plan 😅 It was just me
                  trying to help out a friend having a startup with the
                  technical stuff. Helped them with their data strategy early on
                  and setup the cloud infastructure to be light on the pocket.
                  After this experience I realized this kind of service might be
                  useful to a lot of early stage startups as it does not make
                  sense to hire someone full time at that time in their journey.
                  <br />
                </Typography>
              </Stack>
            </AboutCard>
          </AboutSectionRight>
        </AboutSectionNoWrap>
      </Container>
    </>
  );
};

export default About;
