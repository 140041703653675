import Img04052022 from "../assets/covers/Cover_04052022.png";
import Img21012022 from "../assets/covers/Cover_21012022.png";
import Img12052021 from "../assets/covers/Cover_12052021.jpeg";
import Img14122020 from "../assets/covers/Cover_14122020.png";
import Img28062020 from "../assets/covers/Cover_28062020.png";
import Img11062020 from "../assets/covers/Cover_11062020.jpeg";
import Img23052020 from "../assets/covers/Cover_23052020.png";
import Img11052020 from "../assets/covers/Cover_11052020.jpeg";
import Img01062019 from "../assets/covers/Cover_01062019.png";

const posts = [
  {
    id: "04052022",
    title:
      "How do I make my for loop faster? - Multiprocessing & Multithreading in Python",
    createdAt: "4 May 2022",
    tags: "For Loop",
    timeToRead: "4 min read ",
    image: `${Img04052022}`,
    link: "https://medium.com/mlearning-ai/how-do-i-make-my-for-loop-faster-multiprocessing-multithreading-in-python-8f7c3de36801",
  },

  {
    id: "21012022",
    title: "Three pillars of a “good” software product",
    createdAt: "21 Jan 2022",
    tags: "Software Development",
    timeToRead: "6 min read ",
    image: `${Img21012022}`,
    link: "https://medium.com/analytics-vidhya/three-pillars-of-a-good-software-product-fc839393274c",
  },

  {
    id: "12052021",
    title: "SpellChecker: Everything you might want to know",
    createdAt: "12 May 2021",
    tags: "For LooSpell Check",
    timeToRead: "5 min read ",
    image: `${Img12052021}`,
    link: "https://medium.com/analytics-vidhya/spellchecker-review-71a461e20279",
  },

  {
    id: "14122020",
    title: "Statistical Musings - Part I",
    createdAt: "14 Dec 2020",
    tags: "Black Swan",
    timeToRead: "6 min read ",
    image: `${Img14122020}`,
    link: "https://medium.com/analytics-vidhya/statistical-musings-part-i-2b7737964682",
  },

  {
    id: "28062020",
    title:
      "Topic Modeling: How the news has shifted from the Coronavirus to Black Lives Matter",
    createdAt: "28 Jun 2020",
    tags: "NLP",
    timeToRead: "5 min read ",
    image: `${Img28062020}`,
    link: "https://medium.com/analytics-vidhya/topic-modeling-how-the-news-has-shifted-from-the-coronavirus-to-black-lives-matter-f24b8340f18b",
  },

  {
    id: "11062020",
    title: "Choosing the best ones: Feature Selection",
    createdAt: "11 Jun 2020",
    tags: "Data Science",
    timeToRead: "4 min read ",
    image: `${Img11062020}`,
    link: "https://medium.com/analytics-vidhya/choosing-the-best-ones-feature-selection-cbe3ac7fa698",
  },

  {
    id: "23052020",
    title: "Tweets: You can’t hit unsend (sentiment analysis and web scraping)",
    createdAt: "23 May 2020",
    tags: "Twitter",
    timeToRead: "4 min read ",
    image: `${Img23052020}`,
    link: "https://medium.com/analytics-vidhya/tweets-you-cant-hit-unsend-sentiment-analysis-and-web-scraping-f44a8652f788",
  },

  {
    id: "11052020",
    title: "Hypothesis Testing with Python",
    createdAt: "11 May 2020",
    tags: "Hypothesis Testing",
    timeToRead: "3 min read ",
    image: `${Img11052020}`,
    link: "https://medium.com/analytics-vidhya/hypothesis-testing-with-python-2a76cac2dc71",
  },

  {
    id: "01062019",
    title:
      "Stat tests when you don’t know what the distribution is- Permutation/Randomization Tests",
    createdAt: "01 Jun 2019",
    tags: "Statistics",
    timeToRead: "3 min read ",
    image: `${Img01062019}`,
    link: "https://amisra26.medium.com/stat-tests-when-you-dont-know-what-the-distribution-is-permutation-randomization-tets-10f4cad57fde",
  },
];

export default posts;
