import { styled } from "@mui/material/styles";
import React, { Fragment } from "react";
import { Outlet } from "react-router-dom";
import Footer from "./footer/Footer";
import Navbar from "./navbar/Navbar";
// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;

const MainStyle = styled("div")(({ theme }) => ({
  minHeight: "calc(100vh - 110px)",
  paddingTop: APP_BAR_MOBILE,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up("lg")]: {
    paddingTop: 80,
  },
}));

// ----------------------------------------------------------------------

export function Layout() {
  return (
    <>
      <Navbar />
      <MainStyle>
        <Outlet />
      </MainStyle>
      <Footer />
    </>
  );
}
