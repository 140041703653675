import React from "react";
import Banner from "../../components/common/Banner";
import { Grid, Container, Stack } from "@mui/material";
import WritingCard from "./WritingCard";
import POSTS from "../../_mocks_/writing";
import Pagination from "@mui/material/Pagination";

const Writing = () => {
  const itemsPerPage = 3;
  const [page, setPage] = React.useState(1);
  const [noOfPages] = React.useState(Math.ceil(POSTS.length / itemsPerPage));

  const handleChange = (event, value) => {
    setPage(value);
  };

  return (
    <>
      <Banner
        title="Writing"
        message="need to make sure those thoughts don't evaporate"
      />
      <Container>
        <Grid container spacing={3} my={10}>
          {POSTS.slice((page - 1) * itemsPerPage, page * itemsPerPage).map(
            (post, index) => (
              <WritingCard key={post.id} post={post} index={index} />
            )
          )}
        </Grid>

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          style={{ marginTop: "60px" }}
          spacing={2}
        >
          <Pagination
            count={noOfPages}
            page={page}
            onChange={handleChange}
            defaultPage={1}
            color="primary"
            size="large"
            showFirstButton
            showLastButton
          />
        </Stack>
      </Container>{" "}
    </>
  );
};

export default Writing;
