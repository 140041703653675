// ----------------------------------------------------------------------
function px(value) {
  return `${value}px`;
}

function responsiveFontSizes({ sm, md, lg }) {
  return {
    "@media (min-width:700px)": {
      fontSize: px(sm),
    },
    "@media (min-width:900px)": {
      fontSize: px(md),
    },
    "@media (min-width:1200px)": {
      fontSize: px(lg),
    },
  };
}

const FONT_PRIMARY = "Kalam, cursive";
//"Kalam, cursive";
// font-family: 'Indie Flower', cursive;
// font-family: 'Pacifico', cursive;
const typography = {
  fontFamily: FONT_PRIMARY,
  fontWeightRegular: 400,
  fontWeightBold: 700,

  lightH1: {
    fontWeight: "400",
    lineHeight: "80px",
    letterSpacing: "-1.76px",
    fontSize: "60px",
    ...responsiveFontSizes({ sm: 52, md: 58, lg: 64 }),
  },

  lightH2: {
    fontWeight: "400",
    lineHeight: "56px",
    letterSpacing: "-0.88px",
    fontSize: px(40),
    ...responsiveFontSizes({ sm: 52, md: 58, lg: 64 }),
  },

  lightH3: {
    fontWeight: "400",
    lineHeight: "44px",
    letterSpacing: "-0.704px",
    fontSize: 32,
    ...responsiveFontSizes({ sm: 18, md: 28, lg: 32 }),
  },

  lightH4: {
    fontWeight: "400",
    lineHeight: "32px",
    letterSpacing: "-0.45px",
    fontSize: px(20),
    ...responsiveFontSizes({ sm: 20, md: 24, lg: 24 }),
  },

  lightH5: {
    lineHeight: "30px",
    letterSpacing: "-0.4px",
    fontWeight: "400",
    fontSize: "22px",
  },

  lightH6: {
    fontWeight: "400",
    lineHeight: "28px",
    letterSpacing: "-0.34px",
    fontSize: "20px",
  },

  boldH1: {
    fontWeight: "700",
    lineHeight: "80px",
    letterSpacing: "-1.76px",
    fontSize: "60px",
    ...responsiveFontSizes({ sm: 52, md: 58, lg: 64 }),
  },

  boldH2: {
    fontWeight: "700",
    lineHeight: "56px",
    letterSpacing: "-0.88px",
    fontSize: px(40),
    ...responsiveFontSizes({ sm: 52, md: 58, lg: 64 }),
  },

  boldH3: {
    fontWeight: "700",
    lineHeight: "44px",
    letterSpacing: "-0.704px",
    fontSize: 32,
    ...responsiveFontSizes({ sm: 18, md: 28, lg: 32 }),
  },

  boldH4: {
    fontWeight: "700",
    lineHeight: "32px",
    letterSpacing: "-0.45px",
    fontSize: px(20),
    ...responsiveFontSizes({ sm: 20, md: 24, lg: 24 }),
  },

  boldH5: {
    fontWeight: "700",
    lineHeight: "30px",
    letterSpacing: "-0.4px",
    fontSize: "22px",
  },

  boldH6: {
    fontWeight: "700",
    lineHeight: "28px",
    letterSpacing: "-0.34px",
    fontSize: "20px",
  },

  lightSmall: {
    fontSize: "10px",
    fontWeight: "400",
    lineHeight: "14px",
    letterSpacing: "0.1px",
  },

  boldSmall: {
    fontSize: "10px",
    fontWeight: "700",
    lineHeight: "14px",
    letterSpacing: "0.1px",
  },

  lightSubhead: {
    fontWeight: "400",
    lineHeight: "22px",
    letterSpacing: "-0.12px",
    fontSize: px(14),
    ...responsiveFontSizes({ sm: 14, md: 15, lg: 15 }),
  },

  boldSubhead: {
    fontWeight: "700",
    lineHeight: "22px",
    letterSpacing: "-0.12px",
    fontSize: px(14),
    ...responsiveFontSizes({ sm: 14, md: 15, lg: 15 }),
  },

  lightSubTitle: {
    fontWeight: "400",
    lineHeight: "24px",
    letterSpacing: "-0.25px",
    fontSize: px(16),
    ...responsiveFontSizes({ sm: 16, md: 18, lg: 18 }),
  },

  boldSubTitle: {
    fontWeight: "700",
    lineHeight: "24px",
    letterSpacing: "-0.25px",
    fontSize: px(16),
    ...responsiveFontSizes({ sm: 16, md: 18, lg: 18 }),
  },
};

export default typography;
