import { AppBar, Drawer, IconButton, Toolbar } from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useContext, useEffect, useState } from "react";
import { ReactComponent as SideMenuIcon } from "../../assets/SideMenu.svg";
import NavbarConfig from "./NavbarConfig";
import NavLinks from "./NavLinks";
import Box from "@mui/material/Box";
import pinkglass from "../../assets/pinkglass_thick_senticktext.png";
// import Logo from "../../assets/Logo.svg";
import { Link } from "react-router-dom";
import { ReactComponent as SunIcon } from "../../assets/Sun.svg";
import { ReactComponent as MoonIcon } from "../../assets/Moon.svg";
import { ThemeContext } from "../../store/ThemeContext";

//
const RootStyle = styled(AppBar)(({ theme }) => ({
  position: "fixed",
  background:
    theme.palette.mode === "dark"
      ? theme.palette.mainBg.dark
      : theme.palette.mainBg.light,
}));

const NavBar = styled(Toolbar)(({ theme }) => ({
  maxWidth: "1440px",
  width: "100%",
  margin: "0 auto",
  minHeight: theme.spacing(8),
  [theme.breakpoints.up("lg")]: {
    minHeight: theme.spacing(8),
    padding: theme.spacing(1),
  },
}));

const SideMenu = styled(SideMenuIcon)(({ theme }) => ({
  width: 22,
  height: 22,
  path: {
    fill: theme.palette.primary.lighter,
  },
}));

export const Sun = styled(SunIcon)(({ theme }) => ({
  cursor: "pointer",
  transition: "all 0.5s ease-in-out",
  path: {
    fill: theme.palette.primary.dark,
  },
}));

export const Moon = styled(MoonIcon)(({ theme }) => ({
  cursor: "pointer",
  transition: "all 0.5s ease-in-out",
  path: {
    fill: theme.palette.primary.dark,
  },
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
}));

export default function Navbar() {
  const { changeTheme, theme } = useContext(ThemeContext);

  const [state, setState] = useState({
    mobileView: false,
    drawerOpen: false,
  });

  const { mobileView, drawerOpen } = state;

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 900
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };
    setResponsiveness();

    window.addEventListener("resize", () => setResponsiveness());

    return () => {
      window.removeEventListener("resize", () => setResponsiveness());
    };
  }, []);

  const displayDesktop = () => {
    return (
      <StyledToolbar>
        {logoBrand}

        <div>{getMenuButtons()}</div>

        <Box>
          <IconButton onClick={changeTheme} color="inherit">
            {theme === "dark" ? <Sun /> : <Moon />}
          </IconButton>
        </Box>
      </StyledToolbar>
    );
  };

  const displayMobile = () => {
    const handleDrawerOpen = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: true }));
    const handleDrawerClose = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: false }));

    return (
      <StyledToolbar>
        <div>{logoBrand}</div>

        <Box>
          <IconButton onClick={changeTheme} color="inherit">
            {theme === "dark" ? <Sun /> : <Moon />}
          </IconButton>
        </Box>

        <IconButton
          {...{
            "aria-label": "menu",
            "aria-haspopup": "true",
            onClick: handleDrawerOpen,
          }}
        >
          <SideMenu />
        </IconButton>

        <Drawer
          {...{
            anchor: "right",
            open: drawerOpen,
            onClose: handleDrawerClose,
          }}
        >
          <div>{getDrawerChoices()}</div>
        </Drawer>
      </StyledToolbar>
    );
  };

  const getDrawerChoices = () => {
    return <NavLinks navConfig={NavbarConfig} />;
  };

  const logoBrand = (
    <Link to="/">
      <Box
        component="img"
        src={pinkglass}
        alt=""
        sx={{ height: 110, width: 80 }}
      />
    </Link>
  );

  const getMenuButtons = () => {
    return <NavLinks navConfig={NavbarConfig} />;
  };

  return (
    <RootStyle>
      <NavBar>{mobileView ? displayMobile() : displayDesktop()}</NavBar>
    </RootStyle>
  );
}
