import React from "react";
import { BrowserRouter } from "react-router-dom";
import Router from "./routes";
import ScrollToTop from "./components/ScrollToTop";
import ThemeConfig from "./theme";
import GlobalStyles from "./theme/globalStyles";
import "./App.css";
import { ThemeContextProvider } from "./store/ThemeContext";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function App() {
  return (
    <BrowserRouter>
      <ThemeContextProvider initialTheme={"dark"}>
        <ThemeConfig>
          <ScrollToTop />
          <GlobalStyles />
          <Router />
        </ThemeConfig>
      </ThemeContextProvider>
    </BrowserRouter>
  );
}

export default App;
