import ProjectOneImg1 from "../assets/portfolio/dashboard/linegraph.png";
import ProjectOneImg2 from "../assets/portfolio/dashboard/numbers.png";
import ProjectOneImg3 from "../assets/portfolio/dashboard/piechart.png";
import ProjectOneImg4 from "../assets/portfolio/dashboard/statsagency.png";
import CloudInfaImg from "../assets/portfolio/cloudinfastructure/aws_alert.drawio.png";
import customersupport1 from "../assets/portfolio/customersupportbot/screenshot1.png";
import customersupport2 from "../assets/portfolio/customersupportbot/screenshot2.png";

// { path: "/customersupport-bot", element: <CustomerSupportBot /> }
// { path: "/allergen-detector"},

const portfolio = [
  {
    id: "projectFour",
    title: "Using LLM to resolve edge cases and fix training data",
    description:
      "In the 'Using LLM to Resolve Edge Cases and Fix Training Data' project, I collaborated with a company (Honeycomb.ai) to enhance their allergen detection system in restaurant menus. Leveraging advanced Large Language Model (LLM) techniques, the project focused on resolving complex edge cases and correcting mislabeled data in the training set. This initiative significantly improved the accuracy of allergen identification, ensuring safer dining choices for individuals with food allergies, and established a scalable solution for accurate AI-driven allergen detection in the food industry.",
    intellectualPropertyOwner: "Honeycomb.ai",
    companyName: "Honeycomb",
    role: "Consultant",
    link: "/allergen-detector",
    images: [],
    technology: ["LLM", "OpenAI", "Python", "NLP"],
  },
  {
    id: "projectThree",
    title: "Customer Support Q&A System",
    description:
      "In the 'Customer Support Q&A System' project, I collaborated with a SaaS provider for staffing agencies to create a system using Large Language Models (LLM) and AWS. This innovative system efficiently handles customer inquiries, guides them to relevant resources, and continuously improves through user feedback. It's a significant enhancement to the client support capabilities of staffing agencies, leveraging advanced AI to deliver accurate and adaptive customer service.",
    intellectualPropertyOwner: "Enginehire Inc.",
    companyName: "Enginehire",
    role: "Consultant",
    link: "/customersupport-bot",
    images: [
      // `${customersupport1}`, `${customersupport2}`
    ],
    technology: ["LLM", "AWS", "Vector Database"],
  },
  {
    id: "projectOne",
    title: "Data Dashboard",
    description:
      "Worked with the Enginehire team to set up relevant data pipelines and storage to be able to generate a dashboard for the customers working with them. The dashboard contains metrics that help customers get a bird’s eye view of the operations and serve as the mission control. ",
    intellectualPropertyOwner: "Enginehire Inc.",
    companyName: "Enginehire",
    role: "Consultant",
    link: "/data-dashboard",
    images: [
      // `${ProjectOneImg1}`,
      // `${ProjectOneImg2}`,
      // `${ProjectOneImg3}`,
      // `${ProjectOneImg4}`,
    ],
    technology: ["Python", "Postgres", "AWS"],
  },
  {
    id: "projectTwo",
    title: "Cloud Infastructure",
    description:
      "Worked with the Enginehire team to reudce cloud cost and set up alerts",
    intellectualPropertyOwner: "Enginehire Inc.",
    companyName: "Enginehire",
    role: "Consultant",
    link: "/cloud-infastructure",
    images: [
      // `${CloudInfaImg}`
    ],
    technology: ["AWS SES", "AWS EventBridge", "AWS Lambda"],
  },
];

export default portfolio;
