import { GlobalStyles as GlobalThemeStyles, useTheme } from "@mui/material";
// ----------------------------------------------------------------------

export default function GlobalStyles() {
  const theme = useTheme();
  return (
    <GlobalThemeStyles
      styles={{
        "*": {
          margin: 0,
          padding: 0,
          boxSizing: "border-box",
        },
        html: {
          width: "100%",
          height: "100%",
          WebkitOverflowScrolling: "touch",
        },
        body: {
          width: "100%",
          height: "100%",
          background:
            theme.palette.mode === "dark"
              ? theme.palette.mainBg.dark
              : theme.palette.mainBg.light,
        },

        img: { display: "block", maxWidth: "100%" },
      }}
    />
  );
}
