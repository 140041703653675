import { Box, Container, Divider, Grid, Stack, useTheme } from "@mui/material";
import React from "react";
import {
  CoverImgStyle,
  ProjectDescription,
  ProjectTitle,
  TagTitle,
  TechTagListItem,
  TechTagListItemWrapper,
} from "./Style";

import ProjectOneImg1 from "../../../assets/portfolio/allergendetector/microscope.png";

import Slider from "react-slick";
//

// ----------------------------------------------------------------------
function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  const theme = useTheme();
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      style={{
        ...style,
        height: "2rem",
        width: "2rem",
        display: "block",
        color: theme.palette.primary.main,
        fontSize: "3em",
        right: "9px",
        zIndex: 1,
      }}
      onClick={onClick}
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M9 5l7 7-7 7"
      />
    </svg>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  const theme = useTheme();

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      style={{
        ...style,
        height: "2rem",
        width: "2rem",
        display: "block",
        color: theme.palette.primary.main,

        fontSize: "3em",
        left: "9px",
        zIndex: 1,
      }}
      onClick={onClick}
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M15 19l-7-7 7-7"
      />
    </svg>
  );
}

const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  nextArrow: <SampleNextArrow />,
  prevArrow: <SamplePrevArrow />,
  autoplay: true,
};

//
const AllergenDetector = () => {
  const tags = ["LLM", "OpenAI", "Python", "NLP"];

  const projectImages = [`${ProjectOneImg1}`];

  const theme = useTheme();
  return (
    <Container>
      <Grid container spacing={3} my={5}>
        <Grid item xs={12}>
          <Stack direction="column" spacing={3}>
            <ProjectTitle>
              Using LLM to resolve edge cases and fix training data
            </ProjectTitle>

            <Divider color={theme.palette.primary.light} />

            <Stack direction="column" spacing={1.5}>
              <TagTitle> TAGS </TagTitle>
              <TechTagListItemWrapper>
                {tags.map((tech) => {
                  return (
                    <Stack direction="row">
                      <TechTagListItem label={tech} variant="outlined" />
                    </Stack>
                  );
                })}
              </TechTagListItemWrapper>
            </Stack>

            <Stack direction="column" spacing={1.5}>
              <TagTitle> DESCRIPTION </TagTitle>
              <ProjectDescription>
                In the "Using LLM to Resolve Edge Cases and Fix Training Data"
                project, I collaborated with a company (Honeycomb.ai) to enhance
                their allergen detection system in restaurant menus. Leveraging
                advanced Large Language Model (LLM) techniques, the project
                focused on resolving complex edge cases and correcting
                mislabeled data in the training set. This initiative
                significantly improved the accuracy of allergen identification,
                ensuring safer dining choices for individuals with food
                allergies, and established a scalable solution for accurate
                AI-driven allergen detection in the food industry.
              </ProjectDescription>
            </Stack>

            <TagTitle>INTELLECTUAL PROPERTY OWNER: Honeycomb.ai</TagTitle>

            <Box style={{ margin: "42px 0 0 0" }}>
              {projectImages && (
                <Slider {...settings}>
                  {projectImages.map((image) => {
                    return (
                      <CoverImgStyle component="img" alt="img" src={image} />
                    );
                  })}
                </Slider>
              )}
            </Box>

            <TagTitle>ROLE : Consultant</TagTitle>

            {/* Etc description */}
            <Stack direction="column" spacing={1.5}>
              <ProjectDescription>
                The primary objective of this project was to enhance the
                accuracy and reliability of their allergen identification
                process, leveraging the capabilities of a Large Language Model
                (LLM). <br></br>
                Edge Case Resolution: One of the significant challenges was
                addressing numerous edge cases where conventional algorithms
                struggled to correctly identify potential allergens in complex
                menu descriptions. <br></br>
                Implemented an algorithm to integrate into the current flow
                utilizing state-of-the-art LLM methodologies to dissect and
                understand intricate language patterns and culinary
                terminologies that often led to edge cases.<br></br>
                The resolution of edge cases helped identify another critical
                aspect involving identifying mislabeled data within the training
                dataset, which was essential for improving the overall model
                performance and reliability.
              </ProjectDescription>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </Container>
  );
};

export default AllergenDetector;
